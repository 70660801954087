<script setup lang="ts">
const { data: navigation } = await useAsyncData('navigation', () => fetchContentNavigation(), { default: () => [] });

provide('navigation', navigation);
</script>

<template>
    <div>
        <ClientOnly fallback="span">
            <AppHeader />
            <template #fallback>
                <!-- this will be rendered on server side -->
                <UHeader>
                    <template #logo>
                        Showyourwork <UBadge
                            label="SaaS"
                            variant="subtle"
                            class="mb-0.5"
                        />
                    </template>
                </UHeader>
            </template>
        </ClientOnly>

        <UMain>
            <slot />
        </UMain>

        <AppFooter />
    </div>
</template>
